/* Target the canvas container directly if possible */
.canvas-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Hide scrollbars */
  ::-webkit-scrollbar {
    display: none;
  }
  
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* Team.css */
.fullsize-scroll {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Hide scrollbars */
  ::-webkit-scrollbar {
    display: none;
  }
  
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
/* Target the canvas container directly */
#canvas-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }  