





.header_logoText{
    filter: invert(100%);
}

.header_search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 150px;
    margin: 0 10px;
}

.header_searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header_searchIcon {
    padding: 5px;
    height: 22px !important;
    background-color: lightblue;
    border-radius: 5px;
}

.header_nav {
    display: flex;
    justify-content: space-evenly;
}

.nav_item {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;  
    color: white;
}

.nav_itemLineOne {
    font-size: 10px;
}

.nav_itemLineTwo {
    font-size: 13px;
    font-weight: 800; 
}

.nav_itemBasket {
    display: flex;
    align-items: center;
    color: white;
    margin-right: 50px;
    margin-left: 10px;
}

.nav_basketCount {
    margin-left: 10px;
    margin-right: 10px;
}

.header_menuIcon {
    display: none;
}

@media (max-width: 667px) {
    .header_nav {
      display: none;
    }
    .header_nav.show {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: #131921;
      padding: 10px 0;
      z-index: 99;
    }
    .header_nav.show .nav_item {
      margin: 10px;
    }

    .header_menuIcon {
        display: block;
        margin-right: 10px;
        cursor: pointer;
    }
}
.switch {
	position: relative;
	display: block;
	width: 100px;
	height: 40px;
	padding: 3px;
	margin: 0 10px 0px 0;
	border-radius: 90px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	box-sizing:content-box;
}
.switch-flat {
	padding: 0;
	background: #FFF;
	background-image: none;
}
.switch-flat .switch-label {
	background: #FFF;
	border: solid 2px #eceeef;
	box-shadow: none;
}
.switch-flat .switch-label:after {
	color: #0088cc;
}
.switch-flat .switch-handle {
	top: 6px;
	left: 6px;
	background: #dadada;
	width: 22px;
	height: 22px;
	box-shadow: none;
}
.switch-flat .switch-handle:before {
	background: #eceeef;
}
.switch-flat .switch-input:checked ~ .switch-label {
	background: #ADD7F2;
	/*border-color: #0088cc;*/
}
.switch-flat .switch-input:checked ~ .switch-handle {
	left: 72px;
	background: #0088cc;
	box-shadow: none;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
	box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	background: #E1B42B;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
	position: absolute;
	margin-top: -1.5px;
	margin-left: -2px;
	width: 0px;
	height: 0px;
	border-radius: 100%;
	
}
.switch-handle:before {
	content: "";
	position: absolute;
	top: 70%;
	left: 50%;
	margin: -6px 0 0 -6px;

	
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
	left: 74px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
 
/* Transition
========================== */
.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}

  





.closet-navbar {
	position: fixed;
	top: 5%;
	right: 20%; /* Adjust the percentage as needed */
	z-index: 999; /* Adjust the z-index to control stacking order */
  }

  .container {
	position: fixed !important;
	top: 1%;

	right: 22.5%;
	width: 7.5vw !important;
	height: 15vh !important;
	z-index: 99999999;
	overflow: scroll;
  }