

  .linearBG {

  }
  /* Add this to your CSS */
.fade {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade.hidden {
  opacity: 0;
}


  /* Styling for timeline line */
  .vertical-timeline::before {
    content: '';
 
    top: 0;
    border: 0;
    background: #333;
    background-image: linear-gradient(to right, #000, #fff, #888);
    height: 100%;
    width: 2px;
  }
  /* Styling for timeline element icons */
.vertical-timeline-element-icon {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  left: 3.5px;
  box-shadow: 0 0 0 1px #fff, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05);

}
.vertical-timeline-element-icon::before {

  position: absolute;
  top: 40%;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05);
}

  


  #description {
    margin: 1.25em 0 1.5em 0;
    width: 300px;
    height: 240px;
   
    font-style: normal;
    font-weight: 200;
    font-size: 15px;
    line-height: 18px;
    
    /* or 233% */
   
    display: flex;
    align-items: left;
    text-align: left;
   
   
  }

  
  .vertical-timeline-element-icon {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  
  .vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    opacity: 1;
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.85s;
    -moz-animation: cd-bounce-1 0.85s;
    animation: cd-bounce-1 0.85s;
  }
  
  /* ... (continued) */
  
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.85s;
    -moz-animation: cd-bounce-2 0.85s;
    animation: cd-bounce-2 0.85s;
  }

  .vertical-timeline-element-content {
     
    top: -100px;
  }

 

  
  @media only screen and (max-width: 768px) {
    .title {
  
      text-align: center;
    }
    .per {
  
 
      top: 75px;
      font-size: 0.75rem !important;
      font-weight: 900;
    }
    
    .header {
  
   
      top: 50px;
      font-size: 0.85rem !important;
      font-weight: 900;
    }
    .date {
      font-weight: 900 !important;
      font-size: 1.75em !important;
    }
    #description {
      margin: 1.25em 0 1.5em 0;
      width: 300px;
      height: 240px;
     
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 18px;
      /* or 233% */
     
      display: flex;
      align-items: left;
      text-align: left;
     
     
    }

    
    .vertical-timeline-element-content {
     
      text-align: center;
      color: #fff;
 
      box-shadow: 0px 3px 0px #DDDDDD;
      border-radius: 4px;
      padding: 2em 3em;
      font-size: 2em;
  
      top: -325px;

      left: -20px;
    }

        
    
    .vertical-timeline-element-title {

      scroll-margin-bottom: 20px;
    }
  }




  .animate-on-scroll {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .animate-on-scroll.visible {
    opacity: 1;
    transform: translateY(0);
  }
  

  .animate-on-scroll2 {
    opacity: 1;
    transform: translateY(0);
  }

  