.preloader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999999999;
  }
.preloader-wrapper {
    background-color: #313142;
    width: 100%;
    height: 100vh;
    position: absolute;
    opacity: 1;
    z-index: 99999999999999999;
  
}
.show-preloader {
    opacity: 1;
    pointer-events: auto;
  }
  
  .hide-preloader {
    opacity: 0;
    pointer-events: none;
  }
  .logoName {
    position: absolute;
    //top: 40%;
    width: 100%;
    //overflow: hidden;
    padding: 70px;
    zoom: 2.5;
    z-index: 999999;
}
  
.hidden {
  transform: translateY(-100%);
  transition: transform 1s ease;
  opacity: 0;
  display: none;
}


/* PreloaderWrapper styles */
.PreloaderWrapper {
  background-color: #313142;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 99999999999999999;
  transition: visibility 1s ease, transform 1s ease;
}

.PreloadHidden {
  visibility: hidden;
  opacity: 0;
  display: none;
  transform: translateY(0);
}



.loader {
    position: relative;
    //top: 50%;
    width: 100%;
    display: flex;
    overflow: hidden;
    padding: 70px;
}
.dot {
    position: absolute;
    left: 0;
    width: 35px;
    height: 35px;
    background-color: white;
    //filter: drop-shadow(0 0 10px white);
    border-radius: 50%;
    zoom: 2.5;
    animation: notleft 3s ease infinite;
}
  
  span:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  span:nth-child(3) {
    animation-delay: 0.5s;
  }
  
  span:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  span:nth-child(5) {
    animation-delay: 0.7s;
  }
  
  @keyframes notleft {
    50% {
      left: 50%;
    }
    100% {
      left: 120%;
    }
  }
  
  @keyframes fadeOutAnimation {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
    }
  }
  
  .preloader-wrapper.preloader--completed {
    z-index: -1; /* Move the preloader behind other content */
    animation: fadeOutAnimation 1s ease-in-out forwards;
  }
  

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//*                                                                                                                                   ///
//*                           2nd Animation                                                                                           ///   
//*                                                                                                                                   ///
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
